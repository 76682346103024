import React from "react"
import PropTypes from "prop-types"
import Img from "gatsby-image"
import styled from "styled-components"

const Parent = styled.div`
  position: relative;
  height: 100%;
  background-color: ${({ bc, opacity, hoverOverlay, bgOpacity }) => !hoverOverlay ? "rgba(" + bc + "," + opacity + ")" : "rgba(" + bc + "," + bgOpacity + ")"};
  -webkit-transition: ${({ transitionDelay }) => transitionDelay}s ;
   -moz-transition: ${({ transitionDelay }) => transitionDelay}s ;
   -ms-transition: ${({ transitionDelay }) => transitionDelay}s ;
   -o-transition: ${({ transitionDelay }) => transitionDelay}s ;
   transition: ${({ transitionDelay }) => transitionDelay}s ;
    
    div > picture > img {
    -webkit-transition: ${({ transitionDelay }) => transitionDelay}s !important;
   -moz-transition: ${({ transitionDelay }) => transitionDelay}s !important;
   -ms-transition: ${({ transitionDelay }) => transitionDelay}s !important;
   -o-transition: ${({ transitionDelay }) => transitionDelay}s !important;
   transition: ${({ transitionDelay }) => transitionDelay}s !important;
    }
  &:hover {
    ${({ hoverOverlay, bc, opacity }) => hoverOverlay ? "background-color: rgba(" + bc + "," + opacity + ")" : null};
    
     .gatsby-image-wrapper > img {
         transform: scale(${({ scaleRatio, scale }) => scale ? scaleRatio : "1"});

     }
     div > picture > img {
              transform: scale(${({ scaleRatio, scale }) => scale ? scaleRatio : "1"});

     }
  }
  
 
`

const FakeBgImage = styled(Img)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: ${({ height }) => height};
  z-index: -1;
  border-radius: ${({ borderRadius }) => borderRadius + "px"};
   ${({ backgroundColor }) => backgroundColor ? "background-color:" + backgroundColor : null};
 

  & > img {
    object-fit: cover !important;
    object-position: 0% 0% !important;
    font-family: "object-fit: cover !important; object-position: 0% 0% !important;";

   
  }
   
  @media screen and (max-width: 600px) {
    height: ${({ mobileHeight }) => mobileHeight};
  }
`

const Content = styled.div`
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
`

const BgImage = ({
                   fluid,
                   title,
                   height,
                   mobileHeight,
                   overlayColor,
                   children,
                   className,
                   opacity,
                   hoverOverlay,
                   transitionDelay,
                   scale,
                   scaleRatio,
                   backgroundColor,
                   bgOpacity,
                   borderRadius,
                   alt
                 }) => (
  <Parent bc={overlayColor}
          opacity={opacity}
          hoverOverlay={hoverOverlay}
          transitionDelay={transitionDelay}
          scale={scale}
          scaleRatio={scaleRatio}
          bgOpacity={bgOpacity}
          alt={alt}>
    <FakeBgImage
      fluid={fluid}
      title={title}
      height={height}
      mobileHeight={mobileHeight}
      backgroundColor={backgroundColor}
      borderRadius={borderRadius}
      alt={alt}

    />
    <Content className={className}>{children}</Content>
  </Parent>
)
BgImage.propTypes = {
  fluid: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  height: PropTypes.string,
  mobileHeight: PropTypes.string,
  overlayColor: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  hoverOverlay: PropTypes.bool,
  transitionDelay: PropTypes.string,
  scale: PropTypes.oneOf(["true", "false"]),
  scaleRatio: PropTypes.string,
  backgroundColor: PropTypes.string,
  bgOpacity: PropTypes.string,
  borderRadius: PropTypes.string,
  alt: PropTypes.string
}
BgImage.defaultProps = {
  height: null,
  mobileHeight: null,
  overlayColor: "transparent",
  children: null,
  className: null,
  opacity: "1",
  hoverOverlay: false,
  transitionDelay: "0.5",
  scale: "false",
  scaleRatio: "1.0",
  bgOpacity: "0",
  borderRadius: "0",
  alt: ""
}

export default BgImage