import React, { useState } from "react"
import BackgroundImage from "gatsby-background-image"
import PropTypes from "prop-types"
import SectionSingleVerticalSeperator from "../Misc/SectionSingleVerticalSeperator/sectionSingleVerticalSeperator"
import EmptySpaceSeperator from "../Misc/EmptySpaceSeperator/emptySpaceSeperator"
import addToMailchimp from "gatsby-plugin-mailchimp"
import classes from "./newsletterSection.module.scss"
import { injectIntl } from "react-intl"
import parse from "html-react-parser"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import checkValidation from "../../utility/checkValidation"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import Container from "react-bootstrap/Container"

toast.configure({
  autoClose: 5000,
  draggable: false,
})

const NewsletterSection = (props) => {

  const { formatMessage } = props.intl
  const [submitDisabled, setSubmitDisabled] = useState(true)
  const [submitTrigger, setSubmitTrigger] = useState(false)
  const [email, setEmail] = useState({ value: null })
  const [consentCheck, setConsentCheck] = useState(false)
  const [validation, setValidation] = useState({
    fields: {
      email: {
        isValid: false,
        rules: {
          required: true, email: true,
        },
        invalidMessage: "",
        isTouched: false,

      },
      agree: {
        isValid: false,
        rules: {
          required: true,
        },
        invalidMessage: "",
        isTouched: false,
      },
    },
    isValid: false,

  })

  /**
   * Validates the input whenever it changes
   * @param e
   * @param identifier
   * @private
   */
  const _handleChange = (e, identifier) => {

    // If fields defined in the validation config
    if (validation.fields[identifier]) {
      let value = e.target.value
      let type = null
      // For regular fields send the target value
      // But for checkboxes trigger the change and send the checked attribute value
      if (e.target.type === "checkbox") {
        value = !consentCheck
        setConsentCheck(!consentCheck)
        type = e.target.type
      }
      let validationResult = checkValidation(value, validation.fields[identifier].rules, props.intl.locale, type)

      if (identifier === "email") {
        setEmail({
          value: e.target.value,
        })
      }

      // Change the identifier payload
      let tmpValidationObj = { ...validation }
      tmpValidationObj.fields[identifier] = {
        ...tmpValidationObj.fields[identifier],
        isValid: validationResult.valid,
        isTouched: true,
        invalidMessage: validationResult.message,
      }
      let allIsValid = true

      // CHeck if all fields are valid so we can change the global isValid
      for (let field in tmpValidationObj.fields) {
        if (!tmpValidationObj.fields[field].isValid) {
          allIsValid = field.isValid
        }
      }

      allIsValid ? setSubmitDisabled(false) : setSubmitDisabled(true)
      // Update the Global Validation state
      tmpValidationObj.isValid = allIsValid
      setValidation(tmpValidationObj)
    }


  }
  /**
   * Submits the Email to Mailchimp
   * @param e
   * @private
   */
  const _handleSubmit = e => {
    e.preventDefault()
    setSubmitTrigger(true)
    if (validation.isValid) {
      console.debug(email.value)
      addToMailchimp(email.value) // listFields are optional if you are only capturing the email address.
        .then(data => {
          // I recommend setting data to React state
          // but you can do whatever you want (including ignoring this `then()` altogether)

          if (data.result !== "error") {
            toast.success(formatMessage({ id: "newsletterSubmit" }))
            setSubmitDisabled(true)
          } else {
            let errorMessage = parse(data.msg)
            console.debug(errorMessage)
            toast.error(parse(data.msg)[0])
          }

        })
        .catch(() => {
          // unnecessary because Mailchimp only ever
          // returns a 200 status code
          // see below for how to handle errors
        })
    } else {
      toast.error(formatMessage({ id: "formError" }))
    }

  }

  return (
    <BackgroundImage width="100%" fluid={props.fluid} backgroundColor={props.bgColor}>
      <SectionSingleVerticalSeperator color={props.seperatorColor}/>
      <EmptySpaceSeperator/>
      <h2 style={{ color: props.titleColor }} className="h2TitleStyled text-center textShadowed">{props.title}</h2>
      <p style={{ color: props.textColor }} className="pTextStyled colorLight text-center">{props.subtext}</p>
      <EmptySpaceSeperator/>
      <Row>
        <Col md={12}>
          <Container>
            <Row>
              <Col md={2}/>
              <Col md={8}>
                <form onSubmit={_handleSubmit}>
                  <div className={classes.FormFields}>
                    <input onChange={(e) => _handleChange(e, "email")}
                           className={[classes.InputEmail, submitTrigger && !validation.fields.email.isValid && validation.fields.email.isTouched ? "error" : null].join(" ")}
                           type="email" name="email"
                           id="email"
                           placeholder={formatMessage({ id: "yourEmailNewsletter" })}
                    />
                    {submitTrigger && !validation.fields.email.isValid && validation.fields.email.isTouched ?
                      <label className="validationError">{validation.fields.email.invalidMessage}</label> : null}
                    <EmptySpaceSeperator/>

                    <input type="checkbox" name="agree" value="check" checked={consentCheck}
                           onChange={(e) => _handleChange(e, "agree")} id="agree"/>

                    <label className={classes.CheckBoxLabel}>
                      {
                        parse(formatMessage({ id: "newsletterConsent" }))
                      }
                    </label>
                    <br/>
                    {submitTrigger && !validation.fields.agree.isValid && validation.fields.agree.isTouched ?
                      <label className="validationError">{validation.fields.agree.invalidMessage}</label> : null}
                    <input className={classes.NewsletterSubmit} type="submit" name="submit"
                           value={formatMessage({ id: "register" })} disabled={submitDisabled}/>
                  </div>
                </form>

              </Col>
              <Col md={2}/>
            </Row>
          </Container>
        </Col>


      </Row>
      <EmptySpaceSeperator/>

      <SectionSingleVerticalSeperator color={props.seperatorColor}/>
    </BackgroundImage>
  )
}

NewsletterSection.defaultProps = {
  fluid: {},
  bgColor: "#fff",
  title: "Newsletter",
  subtext: "",
  textColor: "#fff",
  titleColor: "#fff",
  seperatorColor: "#fff",
}
NewsletterSection.propTypes = {
  fluid: PropTypes.object,
  bgColor: PropTypes.string,
  title: PropTypes.string,
  subtext: PropTypes.string,
  titleColor: PropTypes.string,
  textColor: PropTypes.string,
  seperatorColor: PropTypes.string,
}
export default injectIntl(NewsletterSection)
