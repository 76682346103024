import React from "react"
import {Link} from "gatsby";
import PropTypes from "prop-types"
import Col from "react-bootstrap/Col"
import classes from "./PortfolioItem.module.scss"
import BgImage from "../../../Misc/BgImage/bgImage"
import svgArrow from "../../../../images/svg/126490.svg"
import decode from "unescape"

const portfolioItem = (props) => {

  return (
    <Col md={[props.md, classes.portfolioColItem].join(" ")}>

      <div className={classes.portfolioItem}>
        <BgImage
          title={"Portfolio Image Of "+decode(props.title)}
          alt={props.altText}
          fluid={props.fluid}
          overlayColor="0,0,0"
          opacity="0.5"
          hoverOverlay={true}
          scale={"true"}
          scaleRatio="1.1"
          height="100%"
          bgOpacity="0.2"
          borderRadius={props.borderRadius}
        >
          <div className={classes.itemInfoBg}/>
          <div className={classes.itemInfo}>
            <Link to={props.url}>
              <div className={classes.pArrow}>
                <img className={classes.nextArrow} src={svgArrow} alt="Svg Arrow Icon"/>
                <span className={classes.line}/>
              </div>
              <div className={classes.itemContent}>
                <p>{decode(props.category)}</p>
                <h3>{decode(props.title)}</h3>
              </div>

            </Link>
          </div>
        </BgImage>
      </div>
    </Col>

  )

}

portfolioItem.defaultProps = {
  md: 4,
  borderRadius: "0"
}
portfolioItem.propTypes = {
  md: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  borderRadius: PropTypes.string
  // content: PropTypes.string.isRequired,

}

export default portfolioItem