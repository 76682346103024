import translationHelper from "../i18n/helper"

const checkValidation = (value, rules, locale,type=null) => {
  let isValid = true
  let message = ""

  if (rules.required && isValid) {
    isValid = type==="checkbox" ? value : value.trim() !== ""
    message = translationHelper[locale]["inputRequired"]
  }


  if (rules.minLength && isValid) {
    isValid = value.length >= rules.minLength
    message = translationHelper[locale]["inputRequired"]
  }

  if (rules.email && isValid) {
    const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
    isValid = pattern.test(value);
    message = translationHelper[locale]["inputNotEmail"]
  }

  if (rules.maxLength && isValid) {
    isValid = value.length <= rules.maxLength
    message = translationHelper[locale]["inputRequired"]
  }

  return { valid: isValid, message: message }
}

export default checkValidation