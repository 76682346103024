import React from "react"
import classes from "./callToAction.module.scss"
import GradientBtn from "../Misc/GradientButton/gradientButton"
import PropTypes from "prop-types"
import EmptySpaceSeperator from "../Misc/EmptySpaceSeperator/emptySpaceSeperator"
import BackgroundImage from "gatsby-background-image"

const callToAction = (props) => {
  return (
    <BackgroundImage width="100%" fluid={props.fluid} backgroundColor={props.bgColor}>
    <div className={classes.Wrapper}>
      <div className={classes.InsideWrapper}>
        <h2 className="h2TitleStyled text-center mainColor textShadowed">{props.heading}</h2>
        <p className="pTextStyled text-center colorLight">{props.subheading}</p>
        <EmptySpaceSeperator pixels={50}/>
        <GradientBtn url={props.url} btnText={props.btnText} icon={props.icon}/>
      </div>
    </div>
    </BackgroundImage>
  )
}

callToAction.propTypes = {
  heading: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  btnText: PropTypes.string.isRequired,
}


export default callToAction
