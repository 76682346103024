import React from "react"
import PropTypes from "prop-types"
import Row from "react-bootstrap/Row"
import PortfolioItem from "./PortfolioItem/PortfolioItem"
import { injectIntl } from "react-intl"
import localeConfig from "../../../constants/locales"
import {slugResolver} from "../../../i18n/slugResolver"
const portfolioItems = (props) => {
  const { intl: { locale } } = props

  const localePrefix = localeConfig[locale].default ? "" : localeConfig[locale].urlPrefix+"/"
    return (
    <Row>
      {
        props.items.map(item => {

          let category = null;

          let slug = slugResolver(locale,item.node.slug,false)

          // there is a Key = > Val assoc array with category_id => category_name push the value of that array
          if(props.categoryByArray ){
            if(props.categoryId){

              category = props.categoryByArray[props.categoryId]
            }else {
              let categoryId = item.node.project_categories[0]
              category = categoryId ? props.categoryByArray[categoryId] : null
            }
          }

          return (

            item.node.featured_media ?
              <PortfolioItem
                key={item.node.id}
                md={4}
                title={item.node.title}
                url={"/"+localePrefix+"portfolio/"+slug}
                content={item.node.excerpt}
                fluid={item.node.featured_media.localFile.childImageSharp.fluid}
                altText={item.node.featured_media.alt_text}
                date={item.node.modified}
                category={category}
                borderRadius={props.borderRadius}
              /> : null


          )
        })

      }
    </Row>

  )
}

portfolioItems.defaulTypes = {
  categoryByArray: []
}
portfolioItems.propTypes = {
  items: PropTypes.array.isRequired,
  // categoryByArray : PropTypes.array

}
export default injectIntl(portfolioItems)
