import React from "react"
import PropTypes from "prop-types"
import ServiceCardBox from "./ServiceCardBox/ServiceCardBox"
import Row from "react-bootstrap/Row"
import parse from "html-react-parser"
import { injectIntl } from "react-intl"
const servicesGrid = (props) => {

  return (
    <Row>
      {
        props.items ? props.items.map(item => {

          return (

            <ServiceCardBox
              className="mb-4"
              key={item.node.id}
              sm={props.sm}
              md={props.md}
              lg={props.lg}
              title={parse(item.node.title)}
              fluid={item.node.featured_media.localFile.childImageSharp.fluid}
              description={
                props.showText ?
                  <p dangerouslySetInnerHTML={{ __html: item.node.acf.short_description }}/>
                  : null
              }
              // link={item.node.acf.page_template ? "/" + item.node.acf.page_template + "/" + slug : "/" + slug}
              link={item.node.path}
              titleStyle={props.titleStyle}
              noLocalization={true}
              grow={true}
            />


          )
        }) : null
      }

    </Row>
  )
}
servicesGrid.defaultProps = {
  showText: true,
  titleStyle: "dashed",
  md:4,
  sm:6,
  lg:3
}
servicesGrid.propTypes = {
  items: PropTypes.array.isRequired,
  showText: PropTypes.bool,
  titleStyle: PropTypes.oneOf(["dashed", "gradient"]),

}
export default injectIntl(servicesGrid)
