import React from "react"

import Layout from "../components/Layout/layout"
import SEO from "../components/seo"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import TopSectionBgImage from "../components/Layout/TopSectionBgImage/TopSectionBgImage"
import SectionTitleSeperator from "../components/Misc/SectionTitleSeperator/SectionTitleSeperator"
import { graphql } from "gatsby"
import ServicesGrid from "../components/Services/ServicesGrid/servicesGrid"
import BlogItems from "../components/Blog/BlogItems/blogItems"
import PortfolioItems from "../components/Portfolio/PortfolioItems/portfolioItems"
import translationHelper from "../i18n/helper"
import SectionSingleVerticalSeperator
  from "../components/Misc/SectionSingleVerticalSeperator/sectionSingleVerticalSeperator"
import GradientBtn from "../components/Misc/GradientButton/gradientButton"
import EmptySpaceSeperator from "../components/Misc/EmptySpaceSeperator/emptySpaceSeperator"
import BackgroundImage from "gatsby-background-image"
import NewsletterSection from "../components/NewsletterSection/newsletterSection"
import CallToAction from "../components/CallToAction/callToAction"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Slider from "react-slick"
import Img from "gatsby-image"

const IndexPage = (props) => {
  const locale = props.pageContext.locale
  const projectCategories = props.data.allWordpressWpProjectCategories.edges

  const sliderSettings = {
    dots: false,
    autoplay: true,
    autoplaySpeed: 3000,
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 3,
    className: "customersSlider"
  }

  let projectCategoriesMapped = {}

  projectCategories.forEach(category => {
    projectCategoriesMapped[category.node.wordpress_id] = category.node.name
  })
  return (
    <Layout locale={props.pageContext.locale} path={props.path}>
      <SEO title={translationHelper[locale]["homeTitle"]}
           lang={props.pageContext.locale}
           description={translationHelper[locale]["homeDescription"]}
      />

      { /* Slider Section */}

        <TopSectionBgImage
          heading={translationHelper[locale]["indexHeading"]}
          customHeading={translationHelper[locale]["indexHeading"]}
          upperHeading={"<span class='colorLight'>MY<span class='mainColor'>BUSINESS</span>360</span>"}
          text={"<p class='colorLight mainSliderParagraph'>" + translationHelper[locale]["indexSliderText"] + "</p>"}
          fluid={props.data.bgImage.childImageSharp.fluid}
          locale={props.pageContext.locale}
          btnUrl={translationHelper[locale]["offerPageUrl"]}
          btnText={translationHelper[locale]["askForOffer"]}
          imgAlt={translationHelper[locale]["websiteCreatingAndEshop"]}
        />

      { /* Services Section */}

      <Container>
        <Row>
          <Col md={12}>
              <SectionTitleSeperator title={translationHelper[locale]["Services"]}/>

            <ServicesGrid md={6} sm={6} lg={3} items={props.data.services.edges}/>
          </Col>
        </Row>
      </Container>

      <SectionSingleVerticalSeperator/>

      { /* Customers Section Slider */}

      <Slider {...sliderSettings}
      >

        {
          props.data.customersLogo.edges.map((edge, index) =>
            <div key={index} className="customerImageWrapper">
              <Img fluid={edge.node.childImageSharp.fluid} alt={edge.node.name + " Company Logo"}/>
            </div>,
          )
        }

      </Slider>


      { /* Image Comparison Section */}

      <Container fluid={true} className="p-0">
        <BackgroundImage fluid={props.data.darkBlueBgImage.childImageSharp.fluid} backgroundColor="#1f2732">
          <Row noGutters={true}>


            <Col md={12} className="p-0">
              <SectionSingleVerticalSeperator color="#fff"/>
              <EmptySpaceSeperator/>

              <h2
                className="h2TitleStyled mainColor textShadowed text-center">{translationHelper[locale]["fromVisionToReality"]}</h2>
              <p
                className="pTextStyled textShadowed colorLight text-center">{translationHelper[locale]["compareSectionSubText"]}</p>
              <EmptySpaceSeperator pixels={30}/>

              <Container>
                <Row>
                  <Col md={3}/>
                  <Col md={6}>
                    {/*<ReactCompareImage*/}
                    {/*  sliderLineColor="#fc5c63"*/}
                    {/*  sliderLineWidth="5"*/}
                    {/*  leftImage={props.data.leftCompareImage.childImageSharp.fluid.src}*/}
                    {/*  leftImageAlt={translationHelper[locale]["leftCompareImageAlt"]}*/}
                    {/*  rightImage={props.data.rightCompareImage.childImageSharp.fluid.src}*/}
                    {/*  rightImageAlt={translationHelper[locale]["rightCompareImageAlt"]}*/}
                    {/*/>*/}

                      {/*<iframe src="https://cdn.flipsnack.com/widget/v2/widget.html?hash=fhms7q0i1" width="100%" height="480" seamless="seamless" scrolling="no" frameBorder="0" allowFullScreen/>*/}

                  </Col>
                  <Col md={3}/>

                </Row>
                <Row>
                  <Col md={12}>
                    <p className="pTextStyledSmall textShadowed colorLight text-center">
                      {translationHelper[locale]["homePageCompareText"]}
                    </p>
                  </Col>

                </Row>
              </Container>
              <EmptySpaceSeperator/>
              <SectionSingleVerticalSeperator color="#fff"/>

            </Col>

          </Row>
        </BackgroundImage>

      </Container>

      { /* Portfolio Section */}

      <Container>

        <Row>
          <Col sm={12}>
            <SectionTitleSeperator noMgTop  title={translationHelper[locale]["Portfolio"]}/>

            <PortfolioItems categoryByArray={projectCategoriesMapped} borderRadius="4"
                            items={props.data.projects.edges}/>
            <SectionSingleVerticalSeperator/>
            <EmptySpaceSeperator/>
            <GradientBtn url="portfolio" btnText={translationHelper[locale]["moreProjects"]}/>
            <EmptySpaceSeperator/>
            <SectionSingleVerticalSeperator/>
          </Col>
        </Row>
      </Container>

      { /* Gray Section #1 */ }

      <Container fluid={true}  className="p-0" style={{background:"#f4f4f4"}}>
      <Row>
        <Col md={12}>
          <SectionSingleVerticalSeperator />
          <SectionTitleSeperator  hideTop={true} hideBot={true} title={translationHelper[locale]["textSectionOneTitle"]}/>
          <Container>
            <Row>
              <Col md={12}>

                <p className="text-center pTextStyledSmall ">
                  {translationHelper[locale]["textSectionOneText"]}
                </p>
                <p> {translationHelper[locale]["textSectionOneTextTwo"]}</p>
              </Col>
            </Row>
          </Container>
          <SectionSingleVerticalSeperator/>
        </Col>
      </Row>
      </Container>

      { /* Newsletter Section */}

      <Container fluid={true} className="p-0">
        <Row noGutters={true}>
          <Col md={12}>

            <NewsletterSection
              title="Newsletter"
              titleColor="#fc5c63"
              subtext={translationHelper[locale]["newsletterSubText"]}
              bgColor="#1f2732"
              seperatorColor="#fff"
              fluid={props.data.darkBlueBgImage.childImageSharp.fluid}
            />
          </Col>
        </Row>
      </Container>

      { /* Gray Section #2 */}

      <Container fluid={true}  className="p-0" style={{background:"#f4f4f4"}}>
        <Row>
          <Col md={12}>
            <SectionSingleVerticalSeperator />
            <SectionTitleSeperator  hideTop={true} hideBot={true} title={translationHelper[locale]["textSectionTwoTitle"]}/>
            <Container>
              <Row>
                <Col md={12}>

                  <p className="text-center pTextStyledSmall ">
                    {translationHelper[locale]["textSectionTwoSubTitle"]}
                  </p>
                  <p className="">
                    {translationHelper[locale]["textSectionTwoText"]}
                  </p>
                  <p className="text-center pTextStyledSmall ">
                    {translationHelper[locale]["textSectionTwoSubTitleTwo"]}
                  </p>
                  <p> {translationHelper[locale]["textSectionTwoTextTwo"]}</p>
                </Col>
              </Row>
            </Container>
            <SectionSingleVerticalSeperator/>
          </Col>
        </Row>
      </Container>


      { /* Blog Section */}

      <Container>
        <Row>
          <Col sm={12}>
            <SectionTitleSeperator noMgTop title={translationHelper[locale]["Blog"]}/>

          </Col>
        </Row>
        <BlogItems items={props.data.blogs.edges}/>
        <EmptySpaceSeperator pixels={50}/>
      </Container>

      { /* Call to Action Section */}


      <Container fluid={true} className="p-0">
        <Row noGutters={true}>
          <Col md={12}>
            {/*<BackgroundImage width="100%" fluid={props.data.darkBlueBgImage.childImageSharp.fluid} backgroundColor="#1f2732">*/}
            {/*  <Container>*/}
            {/*  <Row>*/}
            {/*    <Col md={6}>*/}
            {/*      <p style={{ color: props.textColor }} className="pTextStyled colorLight text-center mt-4">Θέλετε να κατασκευάσετε τη δική σας ιστοσελίδα ή EShop;</p>*/}
            {/*      <p className="pTextStyledSmall textShadowed colorLight text-center">Η ομάδα της Mybusiness360 είναι εδώ για εσάς!Γνωρίζουμε πως η κατασκευή της Ιστοσελίδας ή του EShop σας είναι πολύ σημαντικά για να αντιμετωπίζονται σαν ένα ακόμα Project. Για το λόγο αυτό τα υλοποιούμε με αξιοπιστία και σεβασμό προς την εταιρεία σας, προσφέροντας εξατομικευμένες λύσεις. Στοχεύουμε στην ανάδειξη της ταυτότητας της επιχείρησης σας χρησιμοποιώντας  σύγχρονα μέσα και πρωτοποριακές τεχνικές, καινοτομώντας για τα δεδομένα της ελληνικής αγοράς. Μικρό ή μεγάλο έργο, μικρός ή μεγάλος πελάτης δέχεται την ίδια αντιμετώπιση και την ίδια αξεπέραστη ποιότητα στην ιστοσελίδα ή το Eshop του.</p>*/}
            {/*    </Col>*/}
            {/*    <Col md={6}>*/}
            {/*      <p style={{ color: props.textColor }} className="pTextStyled colorLight text-center mt-4"> Πως τα καταφέρνουμε;</p>*/}
            {/*      <p className="pTextStyledSmall textShadowed colorLight text-center">Αρχικά θέτοντας τους σωστούς στόχους! Θέλουμε ο συνεργάτης μας να έχει την καλύτερη ιστοσελίδα της αγοράς και αυτό να αποτελεί το ανταγωνιστικό του πλεονέκτημα!*/}
            {/*        Η ομάδα μας αποτελείται από δημιουργικούς και εξειδικευμένους επαγγελματίες με χρόνια εμπειρίας και αξιόλογες συνεργασίες στο διεθνή κλάδο. Για κάθε ξεχωριστό κομμάτι του Project σας υπάρχει ο κατάλληλος άνθρωπος που φροντίζει να τελειοποιήσει την κάθε λεπτομέρεια.Δεν επαναπαυόμαστε ποτέ! Επιμορφωνόμαστε διαρκώς συμμετέχοντας ενεργά σε σεμινάρια και εκπαιδεύσεις ώστε να είμαστε ενημερωμένοι και να εφαρμόζουμε τις πιο σύγχρονες τακτικές στην κατασκευή ιστοσελίδων και Eshop. Σας εξοπλίζουμε με όλα τα νέα Trends για να γίνεται ακόμα πιο ισχυροί από τους ανταγωνιστές σας!</p>*/}
            {/*      <p  className="pTextStyledSmall textShadowed colorLight text-center">            Πριν ξεκινήσουμε την υλοποίηση οποιασδήποτε ιστοσελίδας ή EShop πραγματοποιούμε εκτενή έρευνα του ανταγωνισμού σας. Διασφαλίζουμε έτσι τη χρήση σωστών πρακτικών και μεθόδων για να μεγιστοποιήσουμε τα οφέλη για την επιχείρηση σας.Όταν εμπιστεύεστε τους κατάλληλους συνεργάτες, μπορείτε να είστε σίγουροι ότι η ιστοσελίδα ή το EShop σας θα υλοποιηθούν όπως τους αξίζει!*/}
            {/*      </p>*/}
            {/*    </Col>*/}
            {/*  </Row>*/}

            {/*  </Container>*/}

            {/*</BackgroundImage>*/}


            <CallToAction
              bgColor="#1f2732"
              fluid={props.data.darkBlueBgImage.childImageSharp.fluid}
              heading={translationHelper[locale]["askForOffer"]}
              subheading={translationHelper[locale]["forYourProject"]}
              url={translationHelper[locale]["offerPageUrl"]}
              btnText={translationHelper[locale]["letStart"]}
            />


          </Col>
        </Row>
      </Container>




    </Layout>
  )
}

export const indexPageQuery = graphql`
    query($locale: String!) {
        services: allWordpressPage(filter: {polylang_current_lang: {eq: $locale}, acf: {page_template: {eq: "services"}}}) {
            edges {
                node {
                    id
                    slug
                    path
                    content
                    title
                    template
                    excerpt
                    acf {

                        page_template
                        short_description
                    }
                    featured_media {
                        localFile {
                            childImageSharp {
                                fluid {
                                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                                }
                            }
                        }
                    }
                }
            }
        }
        blogs: allWordpressPost(filter: {polylang_current_lang: {eq: $locale}},limit:3) {
            edges {
                node {
                    featured_media {
                        localFile {
                            childImageSharp {
                                fluid {
                                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                                }
                            }
                        }
                        alt_text
                    }
                    excerpt
                    categories {
                        name
                        slug
                        path
                    }
                    id
                    title
                    slug
                    polylang_current_lang
                    modified(formatString:"MMMM YYYY")
                    tags {
                        name
                    }
                }
            }
        }

        projects:   allWordpressWpProjects(filter: {polylang_current_lang: {eq: $locale}},limit:6) {
            edges {
                node {
                    modified(formatString: "MMMM YYYY")
                    slug
                    excerpt
                    polylang_current_lang
                    wordpress_id
                    type
                    title
                    path
                    project_categories
                    featured_media {
                        localFile {
                            childImageSharp {
                                fluid {
                                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                                }
                            }
                        }
                        alt_text
                    }

                    id
                }
            }
        }

        customersLogo:allFile(filter: {relativeDirectory: {eq: "customers"}}) {
            edges {
                node {
                    id
                    name
                    childImageSharp {
                        fluid(maxWidth:600) {
                            ...GatsbyImageSharpFluid_withWebp_noBase64
                        }
                    }
                }
            }
        }

        bgImage: file(relativePath: {eq: "mybusiness360-bg.jpg"}) {
            childImageSharp {
                fluid(maxWidth:4200) {
                    ...GatsbyImageSharpFluid_noBase64
                }
            }
        }
#        leftCompareImage: file(relativePath: {eq: "sliders/homeCompareSlider/code-editor.jpg"}) {
#            childImageSharp {
#                fluid(maxWidth:1200) {
#                    ...GatsbyImageSharpFluid_withWebp_noBase64
#                }
#            }
#        }
#        rightCompareImage: file(relativePath: {eq: "sliders/homeCompareSlider/nikols-website.jpg"}) {
#            childImageSharp {
#                fluid(maxWidth:1200) {
#                    ...GatsbyImageSharpFluid_withWebp_noBase64
#                }
#            }
#        }
        darkBlueBgImage: file(relativePath: {eq: "bg-images/section-darkblue.png"}) {
            childImageSharp {
                fluid(maxWidth:4200) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                }
            }
        }
        allWordpressWpProjectCategories(filter: {polylang_current_lang: {eq: $locale}}) {
            edges {
                node {
                    slug
                    path
                    wordpress_id
                    name
                }
            }
        }
    }

`

export default IndexPage
