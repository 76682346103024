import React from "react"
import Card from "react-bootstrap/Card"
import Col from "react-bootstrap/Col"
import classes from "./BlogItem.module.scss"
import LocalizedLink from "../../../LocalizedLink"
import PropTypes from "prop-types"
import BgImage from "../../../Misc/BgImage/bgImage"
import decode from "unescape"
import parse from "html-react-parser"

const blogItem = (props) => {


  return (
    <Col md={props.md} className={[classes.blogItemWrapper, props.className].join(" ")}>
      <BgImage
        title="test"
        fluid={props.fluid}
        overlayColor="0,0,0"
        opacity="0.7"
        hoverOverlay={true}
        scale={"true"}
        scaleRatio="1.1"
        height="100%"
        bgOpacity="0.5"
        backgroundColor="#000"
        borderRadius={props.borderRadius}
      >
        {
          props.categoryUrl ?
            <span className={classes.metaCategory}>
              <LocalizedLink to={"/category/" + props.categoryUrl}>{decode(props.category)}</LocalizedLink>
            </span>
            : null
        }

        <LocalizedLink className={classes.BlogLink} to={props.url}>


          <div className={classes.blogContentWrapper}>

            <span className={classes.meta}>{props.date}</span>
            <Card.Title>
              <h3 className={classes.blogItemHeader}>
                {props.title}
              </h3>
            </Card.Title>
            <div className={classes.blogItemExcerpt}
                       // dangerouslySetInnerHTML={{ __html: props.content }}
            >
              { props.content ? parse(props.content) : null}
            </div>


          </div>
        </LocalizedLink>

      </BgImage>
    </Col>

  )

}

blogItem.defaultProps = {
  md: 4,
  borderRadius: "0",
}
blogItem.propTypes = {
  md: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  content: PropTypes.string,
  borderRadius: PropTypes.string,

}
export default blogItem