import React from "react"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import classes from "./TopSectionBgImage.module.scss"
import BackgroundImage from "gatsby-background-image"
import parse from "html-react-parser"
import Container from "react-bootstrap/Container"
import PropTypes from "prop-types"
import GradientBtn from "../../Misc/GradientButton/gradientButton"

const TopSectionBgImage = (props) => {
  return (
    <BackgroundImage
      className={[classes.ClipPath, classes.bgImage].join(" ")}
      fluid={props.fluid}
      // loading="eager"
      // fadeIn={false}
      // critical={true}
      // preserveStackingContext={false}

    >
      <Container>

        <Row>

          <Col md={12} className="h1StyleCol">
            <h2 className='mainHeadingUpperHeading'>

                {props.upperHeading ? parse(props.upperHeading) : null}
            </h2>

            <h1 className="mainColor mainCustomHeading">
                {props.customHeading ? parse(props.customHeading) : props.heading}
            </h1>

              {props.text ? parse(props.text) : null}

            {
              props.btnText && props.btnUrl ?
                <GradientBtn style={{ textAlign: "left", padding: "20px 0 0 0" }}
                             radius="8"
                             url={props.btnUrl}
                             btnText={props.btnText}/> : null
            }
          </Col>
        </Row>
      </Container>

    </BackgroundImage>
  )
}
TopSectionBgImage.defaultProps = {
  imgAlt: ""
}

TopSectionBgImage.propTypes = {
  upperHeading: PropTypes.string,
  customHeading: PropTypes.string,
  text: PropTypes.string,
  fluid: PropTypes.object,
  btnText: PropTypes.string,
  btnUrl: PropTypes.string,
  imgAlt: PropTypes.string
}

export default TopSectionBgImage