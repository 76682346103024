import React from "react"
import PropTypes from "prop-types"
import Row from "react-bootstrap/Row"
import BlogItem from "./BlogItem/BlogItem"
import { masonryPatternOne } from "../../../utility/helpers"
import { injectIntl } from "react-intl"
import {slugResolver} from "../../../i18n/slugResolver"
const blogItems = (props) => {

  const {intl: {locale}}= props
  let i = -1
  let looper = -1


  return (
    <Row>
      {
        props.items.map((item, key) => {
          i++;
          looper++

          let categoryUrl=item.node.categories ? slugResolver(locale,item.node.categories[0].slug,false) : null
          let slug = slugResolver(locale,item.node.slug,false)

          if (props.masonry) {
            if (!masonryPatternOne[i]) {
              i = 0
            }

            if (looper > 2) {
              looper = 0
            }
            return (
              <BlogItem
                key={item.node.id}
                md={masonryPatternOne[i]}
                title={item.node.title}
                url={"/blog/" + slug + "/"}
                fluid={item.node.featured_media.localFile.childImageSharp.fluid}
                altText={item.node.featured_media.alt_text}
                date={item.node.modified}
                category={item.node.categories ? item.node.categories[0].name : null}
                categoryUrl={categoryUrl}
                className={props.className}

              />
            )
          } else {
            return (
              item.node.featured_media ?
                <BlogItem
                  key={item.node.id}
                  md={props.md}
                  title={item.node.title}
                  url={"/blog/" + slug + "/"}
                  content={item.node.excerpt}
                  fluid={item.node.featured_media.localFile.childImageSharp.fluid}
                  altText={item.node.featured_media.alt_text}
                  date={item.node.modified}
                  category={item.node.categories ? item.node.categories[0].name : null}
                  categoryUrl={categoryUrl}
                  borderRadius="4"


                /> : null


            )
          }


        })

      }
    </Row>
  )
}
blogItems.defaultProps = {
  md: 4,
}
blogItems.propTypes = {
  items: PropTypes.array.isRequired,
  md: PropTypes.number,

}
export default injectIntl(blogItems)
